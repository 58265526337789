.artist {
	margin-top: -2rem;
	color: #717171;
	font-size: 1.5rem;
  min-height: 1.5rem;
	font-weight: 400;
}

.line {
	margin-bottom: 0.2rem;
}

.column div {
	display: inline-block;
}

.word {
	display: inline-block;
}

.column {
	display: inline-block;
}

.start_of_chorus {
	padding-left: 0.5rem;
	padding-bottom: 0.2rem;
	border-left: solid lightgrey 0.25rem;
}

.comment {
	font-weight: 200;
	font-size: 1.5rem;
}

.row {
	margin-bottom: 0.3rem;
}

div.chord {
	font-size: 1rem;
	font-weight: 600;
	display: block;
	color: #751aab;
	margin-bottom: -0.2rem;
}

.column, .lyrics {
	display: inline-block;
}

.lyrics {
	font-size: 2rem;
}

.paragraph {
	margin-bottom: 2rem;
}
