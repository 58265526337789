.song-list-backstage {
  h1 {
    display: inline-block;
  }

  th {
    text-align: left;
  }

  td, th {
    padding: 0.1rem 1rem;
  }

  .new-song-button {
    float: right;
    margin-top: 2rem;
  }
}
