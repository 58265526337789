.song-meta-data li {
	font-size: 1rem;
	border-radius: 25px;
	border: solid #717171 1px;
	display: inline;
	padding: 0.1rem 0.5rem 0.3rem 0.5rem;
	margin-left: 0.2rem;
}

.song-meta-data li span {
	font-weight: 500;
}
