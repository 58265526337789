.navigation-public {
  .icon {
    width: 24px;
    height: 24px;
    filter: invert(100%);
    margin-bottom: -5px;
  }

  .share {
    float: right;
    width: 24px;
    height: 24px;
    filter: invert(100%);
    margin-bottom: -5px;
  }

  .sharing {
    background-color: white;
    text-align: center;
    width: 100%;
    height: 100vh;
    margin-top: 50px;
  }

}
