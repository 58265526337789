@import '../_colors.scss';

.backstage-layout {
  display: grid;
  grid-template-columns: 0.6fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
  "menu content content content";

  .menu {
    grid-area: menu;
    background-color: $primary-dark;
    color: $text-light;
  }

  .content {
    grid-area: content;
    padding: 0 1rem;
    overflow: scroll;
  }




  position: absolute;
  margin-top: 66px;
  width: 100vw;
  height: 100%;
}
