.editor {
  h1 {
    display: inline-block;
  }

  textarea {
    width: 100%;
    height: 80vh;

  }

  .button-top {
    float: right;
    margin-top: 2rem;
  }

  .cancel {
    margin-right: 0.5rem;;
  }

  .remove-lines {
    margin-right: 0.5rem;
  }

  .meta-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 2rem 2rem;
  }
}

.DraftEditor-root {
  border: solid grey 1px;
}
