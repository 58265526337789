ul {
	list-style: none;
	padding-inline-start: 0;
}

li {
	margin-bottom: 0.5rem;
}

li a {
	color: black;
	text-decoration-color: #751aab;
}

.last-in-block {
	margin-bottom: 2rem;
}

.by {
	font-size: 1rem;
	color: #717171;
}
