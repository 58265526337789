@import '../_colors.scss';

.input {
  input {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    border-color: $primary;
  }

}
