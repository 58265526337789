.parser-errors {
  background-color: #ff7c7c;
  border-radius: 0.2rem;
  padding: 0.1rem 0.5rem;
}


.parser-errors h2 {
  font-size: 1.2rem;
}
