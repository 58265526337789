.hideoverflow {
	overflow: hidden;
}

.outer {
	position: relative;
	left: 50%;
	float: left;
}

.inner {
	position: relative;
	left: -50%;
	float: left;
	margin-left: 1.2rem;
	margin-right: 1.2rem;
	margin-bottom: 2rem;
	margin-top: 100px;;
}

body, input {
	font-size: 1.3rem;
}
