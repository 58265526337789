nav.top-navigation {
  background-color: #751aab;
  vertical-align: center;
  padding-top: 1rem;
  padding-left: 2%;
  padding-right: 2%;
  height: 50px;
  color: white;
  font-weight: 800;
  position: fixed;
  width: 96%;
  z-index: 1;

  a {
    color: white;
    text-decoration: none;
  }
}
