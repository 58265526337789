@import '../_colors.scss';

.signin {
  .button {
    text-align: right;
  }

  .error {
    font-size: 1rem;
    color: $error;
  }
}
